import React from 'react';
import { LanguageContext } from '../../../App';
import './vision.css';

const clientTranslated = {
    en: {
        title: 'CLIENTS',
        content: ["The company has more than twenty clients in various sectors and our goal is to maintain our ranking as one of the best security service providers in the Kingdom of Saudi Arabia, to ensure continuous compliance with international security standards and to provide the highest levels of protection for our clients' buildings and assets.",
       " Financial institutions and banks ",
        "Leading commercial companies",
        "Saudi commissions ",
        "Telecommunications Company"]
    },
    ar: {
        title: 'عملائنا',
        content: ['لدى الشركة اكثر من عشرون عميل في مختلف القطاعات،  وهدفنا هو الحفاظ على ترتيبنا كواحدة من افضل مزودي الخدمات الأمنية في المملكة العربية السعودية، ضماناً للمواكبة المستمرة للمعايير الأمنية الدولية وتوفير اعلى مستويات الحماية لمباني عملائنا وأصولهم للقطاعات التالية:',

            'المؤسسات المالية والبنوك. ',
            'الشركات التجارية الرائدة.',
            'الهيئات السعودية .',
            'شركات الاتصالات.']
    }
}

function Client() {
    const { lang } = React.useContext(LanguageContext);

    return (
        <div id="client" className={"text-justify "+(lang==="en"?'f-en':'f-ar')}>
            <div className="cpx-5 py-5">
                <h1 className="mb-5 fs-3 title">{clientTranslated[lang].title}</h1>
                <p className={`${lang==="en"?"fs-24 ":"afs-20 "} client-c `}>
                {clientTranslated[lang].content[0]}
                </p>
                <p className={`${lang==="en"?"fs-24 ":"afs-20 "} client-c mt-5 `}>
                {clientTranslated[lang].content[1]}  <br />
                {clientTranslated[lang].content[2]}<br />
                {clientTranslated[lang].content[3]}<br />
                {clientTranslated[lang].content[4]}

                </p>
            </div>
        </div>
    )
}

export default Client;