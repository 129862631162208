import React from 'react'
import { LanguageContext } from '../../../App'
import security from '../../../assets/images/Rectangle1.png'
import './about.css'

const aboutTranslate = {
    en: {
        title: 'ABOUT US',
        content: [
            ' Safety & Security Company (SSCo) was established in 1995 to provide security services across the kingdom of Saudi Arabia. For the past 25 years, the company has managed to place itself as the leader in the security services sector. Later in 2009, the company established Cash in transit (CIT) service as  as well as security systems and CCTVs.',
            'SSCo has branches across KSA, offces to serve our clients are available in more than 30 cities/towns in Saudi Arabia.',
            '',
            ''
        ]
    },
    ar: {
        title: 'نبذة',
        content: [
            'تأسست شركة الأمن والأمان للحراسات الأمنية المدنية (SSCo) في عام 1995 لتقديم خدمات الأمن في جميع أنحاء المملكة العربية السعودية. ',
            ' تمكنت الشركة من وضع نفسها  كشركة رائدة في قطاع الخدمات الأمنية على مدار 25 عاماً .',
            'أضافت الشركة عام 2009م نشاط خدمة نقل النقود والمقتنيات الثمينة  (CIT) وكذلك أنظمة الأمن والدوائر التلفزيونية المغلقة.',
            'لدى الشركة فروع ومكاتب اقليمية لخدمة العملاء متوفرة في أكثر من 30 مدينة في كافة أنحاء المملكة .'
        ]
    }

}

function About() {
    const { lang } = React.useContext(LanguageContext);
    return (
        <div id="about">
            <div className="row m-0 p-0 pb-5">
                <div className={"col-sm-6 col-order  "+ (lang==="en"?'pll-5 f-en':'prr-5 f-ar')}>
                    <h1 className={"py-4  title fs-3 "+(lang==="en"?'':'text-right')}>{aboutTranslate[lang]['title']}</h1>
                    <p className={`${lang==="en"?"fs-24 text-justify":"afs-20 text-right"} text-desc `}>
                        {aboutTranslate[lang]['content'][0]}
                    </p>
                    <p className={`${lang==="en"?"fs-24 text-justify":"afs-20 text-right"} text-desc `}>
                        {aboutTranslate[lang]['content'][1]}
                    </p>
                    <p className={`${lang==="en"?"fs-24 text-justify":"afs-20 text-right"} text-desc `}>
                        {aboutTranslate[lang]['content'][2]}
                    </p>
                    <p className={`${lang==="en"?"fs-24 text-justify":"afs-20 text-right"} text-desc `}>
                        {aboutTranslate[lang]['content'][3]}
                    </p>
                </div>
                <div className={"col-sm-6 "+(lang==="en"?'img-col':'img-col-ar')}>
                    <img src={security} className="img-fluid w-90" alt="security"></img>
                </div>
            </div>
        </div>
    )
}

export default About;
