import './footer.css'
import mapLeft from '../../../assets/images/dots2.svg'
import mapRight from '../../../assets/images/dots3.svg'
import React from 'react'
import { LanguageContext } from '../../../App'

const footerTranslated = {
    en:{
        title:'OUR CONTACT',
        address:'ADDRESS:',
        area:' Alamin Abdullah Alnaim St, Riyadh, Kingdom of Saudi Arabia',
        telephone:'TELEPHONE:',
        no:'920001267',
        email:'EMAIL:',
        emailId:'INFO@SS-CO.COM.SA',
        subscribe:'SUBSCRIBE'

    },
    ar:{
        title:'تواصل معنا:',
        address:'العنوان:',
        area:'شارع الأمين عبدالله النعيم ، الرياض ، المملكة العربية السعودية',
        telephone:'رقم التواصل:',
        no:'920001267',
        email:'الايميل:',
        emailId:'INFO@SS-CO.COM.SA',
        subscribe:'الإشتراك'
}
}

function Footer() {
    const {lang} = React.useContext(LanguageContext);
    return (
        <div className={"container-fluid footer-bg  demo-wrap-footer " +(lang=="en"?'f-en':'f-ar text-right')} >
            <img src={mapLeft} className="demo-bg-footer" alt="right" />
            <img src={mapRight} className="demo-bg-footer-right" alt="left" />
            <div className="row m-0 p-0  py-10 demo-content-footer">
                <div className="col-sm-2"></div>
                <div className="col-md-6 text-desc ">
                    <h3 className="text-desc  ">{footerTranslated[lang].title}</h3>
                    <div className="row">
                        <div className="col-4 col-sm-3">
                            <p className="fs-22 mb-0"><span className="contact-label">{footerTranslated[lang].address}</span> </p>
                        </div>
                        <div className="col-8 col-sm-9">
                            <p className="fs-22 mb-0"> {footerTranslated[lang].area}</p>
                        </div>
                    
                        <div className="col-4 col-sm-3 align-line">
                            <p className="fs-22 mb-0 "><span className="contact-label">{footerTranslated[lang].telephone}</span> </p>
                        </div>
                        <div className="col-8 col-sm-9">
                            <p className="fs-22 mb-0 align-line-content"> {footerTranslated[lang].no}</p>
                        </div>
                  
                        <div className="col-4 col-sm-3">
                            <p className="fs-22 mb-0"><span className="contact-label">{footerTranslated[lang].email}</span> </p>
                        </div>
                        <div className="col-8 col-sm-9">
                            <p className={"fs-22 mb-0 f-en " +(lang=="en"?'':'fw-600')}> {footerTranslated[lang].emailId}</p>
                        </div>
                    </div>
                </div>
                <div className="col-sm-3 sm-mt">
                    <h3 className="text-desc  pl-2">{footerTranslated[lang].subscribe}</h3>
                    <input type="text" className="d-block form-control custom-email mb-4 contact-label text-desc f-en" placeholder="YOUR EMAIL" />
                    <div className="social-icons fs-24">
                        <i className="fab fa-facebook"></i>
                        <i className="fab fa-twitter"></i>
                        <i className="fab fa-youtube"></i>
                        <i className="fab fa-instagram"></i>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer;