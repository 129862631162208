import './vision.css';
import ranking from '../../../assets/images/ranking.png';
import ranking2 from '../../../assets/images/ranking2.png';
import React from 'react';
import { LanguageContext } from '../../../App';

const rankingTranslated = {
    en: {
        title: 'RANKING AND VOLUME OF BUSINESS',
        content: ['SSCo has tier 1 license from the Ministry of Interior to serve in the private security & CIT felds. We place ourselves as the top company in our market  due to the size of business, customer satisfaction rate, and track record.',
        '',
            'As of Q1 2021, SSCo has more than 350 patrol vehicles, 600 long range walkie talkies, and over 1000 CCTV cameras installed.','']
    },
    ar: {
        title: 'تصنيفنا وحجم اعمال ',
        content:[
            'ترخيص مزاولة النشاط من الفئة الأولى (أ) صادر من مقام وزارة الداخلية.',
           'أصبحنا من أفضل  الشركات في السوق نظرًا لمنجزاتنا وسجلنا الحافل ومعدل رضا عملائنا.',
            'لدى الشركة أسطول من المركبات ذات الموديلات الحديثة (الدوريات) المجهزة أمنياً،  ومصفحات نقل الأموال المجهزة بكافة وسائل الحماية والحقائب الذكية وأنظمة التتبع في جميع فروع الشركة بكافة انحاء المملكة.',
            'جميع المشاريع تم ربطها بأجهزة اتصال لاسلكي طويل المدى.'
        ]
    }
}

function Ranking() {
    const { lang } = React.useContext(LanguageContext);

    return (
        <div className={(lang==="en"?'vision-bg f-en txt-justify':'vision-bg-ar f-ar text-right') + " row m-0 p-0 pt-5 cpx-5 "} id="ranking">
            <div className="col-sm-10 p-0">
                <div className=" pbb-5">
                    <h1 className="mb-5 fs-3 lh">{rankingTranslated[lang]['title']}</h1>
                    <p className={`${lang==="en"?"fs-24 ":"afs-20 "} para-w `}>{rankingTranslated[lang]['content'][0]}<br/>{rankingTranslated[lang]['content'][1]}
                    </p>
                    <p className={`${lang==="en"?"fs-24 ":"afs-20 "} mb-0 para-w`}>
                    {rankingTranslated[lang]['content'][2]}<br/>{rankingTranslated[lang]['content'][3]}
                    </p>
                </div>
            </div>
            <div className="col-sm-2 text-center mbb-2">
                <img src={ranking} className="img-fluid ranking-width mt-2" alt="ranking1" />
                <img src={ranking2} className="img-fluid mt-3 ranking-w2" alt="ranking2" />
            </div>
        </div>

    )
}

export default Ranking;