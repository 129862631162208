import React from 'react';
import { LanguageContext } from '../../../App';
import service from '../../../assets/images/service.png';
import service2 from '../../../assets/images/service2.png';
import service3 from '../../../assets/images/service3.png';

const serviceTranslate = {
    en: {
        title: 'OUR SERVICES',
        content: ['Security systems installation', 'maintenance and management.', 'Cash, precious metals, and', ' valuable documents transportation.', 'Private civil security services.', '']
    },
    ar: {
        title: 'خدماتنا',
        content: [
            'تركيب وتشغيل وصيانة            ',
            'أنظمة الأمن والسلامة',
            'نقل النقود والمعادن الثمينة            ',
            'والمستندات القيمة            ',
            'خدمة الحراسات الأمنية',
            '     المدنية الخاصة '
        ]
    }
}

function Services() {
    const { lang } = React.useContext(LanguageContext);

    return (
        <div className={"cpx-5 py-5 " + (lang === "en" ? 'f-en' : 'f-ar')} id="ourservices" style={{ "direction": "ltr" }}>
            <h1 className={"mb-5 fs-3 title " + (lang === "en" ? '' : 'text-right')}>{serviceTranslate[lang]['title']}</h1>
            <div className="row text-center m-0 p-0 mtt-5">
                <div className="col-sm-4">
                    <img src={service} className="mb-4 img-width-service" alt="s1"></img>
                    <p className={`${lang === "en" ? "fs-24 " : "afs-20 "} text-desc `}>{serviceTranslate[lang]['content'][0]}<br />{serviceTranslate[lang]['content'][1]}</p>
                </div>
                <div className="col-sm-4 col-mt">
                    <img src={service2} className="mb-4 img-width-service" alt="s2"></img>
                    <p className={`${lang === "en" ? "fs-24 " : "afs-20 "} text-desc `}>{serviceTranslate[lang]['content'][2]}<br />{serviceTranslate[lang]['content'][3]}</p>
                </div>
                <div className="col-sm-4 col-mt">
                    <img src={service3} className="mb-4 img-width-service" alt="s3"></img>
                    <p className={`${lang === "en" ? "fs-24 " : "afs-20 "} text-desc `}>{serviceTranslate[lang]['content'][4]}<br/>{serviceTranslate[lang]['content'][5]}</p>
                </div>
            </div>
        </div>
    )
}

export default Services;