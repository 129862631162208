import React, { useEffect } from 'react';
import { LanguageContext } from '../../../App';
import security from '../../../assets/images/dots1.svg'
// import securityTile from '../../../assets/images/securityTile.png'
import service1 from '../../../assets/service/service1.svg';
import service2 from '../../../assets/service/service2.svg';
import service3 from '../../../assets/service/service3.svg';
import service4 from '../../../assets/service/service4.svg';
import ser_1 from '../../../assets/service/1.png';
import ser_2 from '../../../assets/service/2.png';
import ser_3 from '../../../assets/service/3.png';
import ser_4 from '../../../assets/service/4.png';
import ser_5 from '../../../assets/service/5.png';

const securityTranslate = {
    en: {
        title: 'SECURITY GUARD SELECTION AND TRAINING',
        content1: '',
        content: 'Based on clients’ requirements, SSCo provides the most suitable security guards for the mission. Our onboarding process runs through the following:',
        subContent: ['Btaining all legal documents such as a saudi civil id and other certifcates.', 'Using MoI’s portal, SSCo runs a criminal record check to ensure that the new applicant is free of criminal history.',
            'Nce the new joiner’s fle is completed, he/she is handed the required job suit and kit.',
            'Training process runs through the main on job requirements depending on the client and task. Varying from the use of fre extinguishers (all types), frst aid, CIT, access control, etc.'
        ],
        number: [1, 2, 3, 4]
    },
    ar: {
        title: ' اختيار رجل امن والتدريب',
        content: 'توفر الشركة حراس الأمن المتدربين على أداء المهام والواجبات الأمنية ويتم اختيار الأفراد المناسبين حسب احتياج المشروع ومتطلبات العمل، كما يتم تدريب رجال الأمن بمقرات الشركة ولدى العميل. ',
        content1: 'أدناه خطوات وإجراءات توظيف رجال الأمن:',
        subContent: [
            'الحصول على جميع الوثائق القانونية من المتقدم والتي تتمثل في بطاقة احوال المدنية السعودية والشهادات اخرى .',
            'باستخدام بوابة وزارة الداخلية، تقوم SSCo بإجراء فحص للسجلات الجنائية للتأكد من خلو مقدم الطلب الجديد من السجل الجنائي .',
            'بمجرد اكتمال ملف المتقدم الجديد، يتم تسليمه الزي الخاص بالعمل زي المعدات امنية المطلوبة .',
            'تمر عملية التدريب من خلال متطلبات الوظيفة الرئيسية حسب العميل والمهمة وتختلف من استخدام طفايات الحريق (جميع انواع) واسعافات اولية، CIT، مراقبة الدخول، الخ .'
        ],
        number: ['١',
            '٢',
            '٣',
            '٤'
        ]
    }
}
function useMedia() {
    const [matches, setMatches] = React.useState(
      window.matchMedia("(max-width: 767px)").matches
    );
 
    // Activity normally for componentDidMount + componentDidUpdate
    useEffect(() => {
     const media = window.matchMedia("(max-width: 767px)");
     if (media.matches !== matches) {
       setMatches(media.matches);
     }
 
     const listener = () => setMatches(media.matches);
     media.addListener(listener);
 
     return () => media.removeListener(listener);
    }, []);
 
    // publish value for render
    return matches; 
 }

function Security() {
    const { lang } = React.useContext(LanguageContext)
    let ser1 = ser_1
    let ser2 = ser_2
    let ser3 = ser_3
    let ser4 = ser_4
    let ser5 = ser_5

   


        if (!useMedia()) {
            ser1 = ser_1
            ser2 = ser_2
            ser3 = ser_3
            ser4 = ser_4
            ser5 = ser_5
        } else {
            ser1 = ser_1
            ser2 = ser_3
            ser3 = ser_4
            ser4 = ser_2
            ser5 = ser_5
        }



    return (
        <div className={"cpx-5 pt-4 demo-wrap py-5 text-justify" + (lang === "en" ? 'f-en' : 'f-ar')} style={{ backgroundColor: "#F5F5F7" }} id="training">
            <img src={security} className="demo-bg" alt="bg" />
            <div className="demo-content">
                <h1 className={"mb-5 fs-3 title lh " + (lang === "en" ? 'f-en' : 'f-ar text-right')}>{securityTranslate[lang]['title']}</h1>
                <p className={"text-desc  para-w mbb-3 " + (lang === "en" ? 'f-en fs-24' : 'f-ar afs-20 text-right')}>{securityTranslate[lang]['content']}<br />{securityTranslate[lang]['content1']}</p>
                <div className="row m-0 p-0">
                    <div className="col-sm-1 d-sm-none d-md-block"></div>
                    <div className="col-sm-4 sm-m">
                        <div className="block-container">
                            <img src={lang === "en" ? service1 : ser1} alt="Norway" />
                            {/* <div class="text-block">
                                <div className={"fs-24 block-container row "+(lang==="en"?'ff-myraid':'f-ar text-right')}><p className="fs-24 block-container col-sm-1">{securityTranslate[lang]['number'][0]}-</p><p className="fs-24 block-container col-sm-10 m-0 pl-1">{securityTranslate[lang]['subContent'][0]} </p>
                                </div>
                            </div> */}
                        </div>
                    </div>
                    <div className="col-sm-2 d-sm-none d-md-block"></div>
                    <div className="col-sm-4 sm-m">
                        <div className="block-container">
                            <img src={lang === "en" ? service2 : ser4} alt="Norway" />
                            {/* <div class="text-block">
                                <div className={"fs-24 block-container row "+(lang==="en"?'ff-myraid':'f-ar text-right')}><p className="fs-24 block-container col-sm-1">{securityTranslate[lang]['number'][1]}-</p><p className="fs-24 block-container col-sm-10 m-0 pl-1">{securityTranslate[lang]['subContent'][1]} </p>
                                </div>
                            </div> */}
                        </div>
                    </div>
                    <div className="col-sm-1 d-sm-none d-md-block"></div>
                    <div className="col-sm-1 d-sm-none d-md-block"></div>
                    <div className={lang == "en" ? 'col-sm-4 mt-3' : 'col-sm-4 mt-3 m-s-b-3'}>
                        <div className="block-container">
                            <img src={lang === "en" ? service3 : ser2} alt="Norway" className="" />
                            {/* <div class="text-block">
                                <div className={"fs-24  block-container row "+(lang==="en"?'ff-myraid':'f-ar text-right')}><p className="fs-24  block-container col-sm-1">{securityTranslate[lang]['number'][2]}-</p><p className="fs-24 block-container col-sm-10 m-0 pl-1"> {securityTranslate[lang]['subContent'][2]}
                                </p>
                                </div>
                            </div> */}
                        </div>
                    </div>
                    <div className="col-sm-2 mt-3 d-none d-sm-none d-md-block"></div>
                    <div className="col-sm-4 " className={lang == "en" ? 'col-sm-4 mt-3' : 'col-sm-4'}>
                        {lang === "en" ? <div className="block-container">
                            <img src={lang === "en" ? service4 : ''} alt="Norway" className="" />
                            {/* <div class="text-block">
                                <div className={"fs-24  block-container row "+(lang==="en"?'ff-myraid':'f-ar text-right')}><p className="fs-24 block-container col-sm-1">{securityTranslate[lang]['number'][3]}-</p><p className="fs-24 block-container col-sm-10 m-0 pl-1"> {securityTranslate[lang]['subContent'][3]}
                                </p>
                                </div>
                            </div> */}
                        </div> : <div />
                        }
                    </div>
                    <div className="col-sm-1 d-sm-none d-md-block"></div>
                    <div className="col-sm-1 d-sm-none d-md-block"></div>
                    <div className="col-sm-4 mt-s-3">
                        {lang !== "en" ? <div className="block-container">
                            <img src={lang === "en" ? service4 : ser3} alt="Norway" className="" />
                            {/* <div class="text-block">
                                <div className={"fs-24  block-container row "+(lang==="en"?'ff-myraid':'f-ar text-right')}><p className="fs-24 block-container col-sm-1">{securityTranslate[lang]['number'][3]}-</p><p className="fs-24 block-container col-sm-10 m-0 pl-1"> {securityTranslate[lang]['subContent'][3]}
                                </p>
                                </div>
                            </div> */}
                        </div> : <div />}
                    </div>
                    <div className="col-sm-2 mt-3 d-none d-sm-none d-md-block"></div>
                    <div className="col-sm-4 mt-3">
                        {lang !== "en" ? <div className="block-container">
                            <img src={ser5} alt="Norway" className="" />
                            {/* <div class="text-block">
                                <div className={"fs-24  block-container row "+(lang==="en"?'ff-myraid':'f-ar text-right')}><p className="fs-24 block-container col-sm-1">{securityTranslate[lang]['number'][3]}-</p><p className="fs-24 block-container col-sm-10 m-0 pl-1"> {securityTranslate[lang]['subContent'][3]}
                                </p>
                                </div>
                            </div> */}
                        </div> : <div />
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Security;