import logo from '../../../assets/images/logo.png'
import cross from '../../../assets/images/cross.svg'
import { Link } from "react-scroll";
import './header.css';
import React from 'react';
import { LanguageContext } from '../../../App';

const headerTranslate = {
    en: ['ABOUT US', 'OUR SERVICES', 'RANKING', 'TRAINING', 'CLIENTS'],
    ar: ['عملائنا', 'اختياراتنا', 'تصنيفنا', 'خدماتنا', 'عنا']
}

function Header() {
    const { lang, changeLanguage } = React.useContext(LanguageContext)
    const [navHeight, setNavHeight] = React.useState("0%")

    return (
        <div className="row m-0 p-0 custom-col">
            <div className="col-6 col-sm-2 pl-0">
                <img src={logo} className="img-fluid custom-img" alt="logo"></img>
            </div>
            <div className="col-6 col-sm-10 pl-0 custom-align">
                <nav className="navbar navbar-expand-md navbar-light nv-position">
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                       {lang==="en"? ( <ul className={"navbar-nav "}>
                            <li className="mxx-3 nav-item">
                                <Link to="about" activeClass="active"  spy={true} smooth={true} className={`nav-link pb-1 active  ${lang==="en"?'f-en':'f-ar'}`}>
                                    {headerTranslate[lang][0]}
                                </Link>
                            </li>


                            <li className="mxx-3 nav-item">
                                <Link to="ourservices"  spy={true} smooth={true} className={`nav-link  pb-1 ${lang==="en"?'f-en':'f-ar'}`} >{headerTranslate[lang][1]}
                                </Link>
                            </li>
                            <li className="mxx-3 nav-item">
                                <Link to="ranking"  spy={true} smooth={true} className={`nav-link  pb-1   ${lang==="en"?'f-en':'f-ar'}`} >{headerTranslate[lang][2]}
                                </Link>
                            </li>
                            <li className="mxx-3 nav-item">
                                <Link to="training"  spy={true} smooth={true} className={`nav-link  pb-1   ${lang==="en"?'f-en':'f-ar'}`}>{headerTranslate[lang][3]}
                                </Link>
                            </li>
                            <li className="mxx-3 nav-item">
                                <Link to="client" spy={true} smooth={true} className={`nav-link  pb-1   ${lang==="en"?'f-en':'f-ar'}`}>{headerTranslate[lang][4]}
                                </Link>
                            </li>
                        </ul>):(<ul className={"navbar-nav "}>
                            <li className="mxx-3 nav-item">
                                <Link to="client" activeClass="active"  spy={true} smooth={true} className={`nav-link pb-1  ${lang==="en"?'f-en':'f-ar'}`}>
                                    {headerTranslate[lang][0]}
                                </Link>
                            </li>


                            <li className="mxx-3 nav-item">
                                <Link to="training"  spy={true} smooth={true} className={`nav-link  pb-1 ${lang==="en"?'f-en':'f-ar'}`} >{headerTranslate[lang][1]}
                                </Link>
                            </li>
                            <li className="mxx-3 nav-item">
                                <Link to="ranking"  spy={true} smooth={true} className={`nav-link  pb-1   ${lang==="en"?'f-en':'f-ar'}`} >{headerTranslate[lang][2]}
                                </Link>
                            </li>
                            <li className="mxx-3 nav-item">
                                <Link to="ourservices"  spy={true} smooth={true} className={`nav-link  pb-1   ${lang==="en"?'f-en':'f-ar'}`}>{headerTranslate[lang][3]}
                                </Link>
                            </li>
                            <li className="mxx-3 nav-item">
                                <Link to="about" spy={true} smooth={true} className={`nav-link  pb-1 active  ${lang==="en"?'f-en':'f-ar'}`}>{headerTranslate[lang][4]}
                                </Link>
                            </li>
                        </ul>)}
                        <div className={lang === "en" ? "ml-auto" : "mr-auto"}>
                            <span href="javascript:void(0)" className="lang f-en " onClick={() => changeLanguage()} >{lang === 'en' ? 'AR' : 'EN'}</span>
                        </div>
                    </div>
                    <div className={(lang === "en" ? "ml-auto" : "mr-auto")+" d-sm-block d-md-none"} style={{"marginTop":"35% !important"}}>
                        <span style={{ "fontSize": "30px", "cursor": "pointer", color:"#3772B8" }} onClick={() => setNavHeight("90%")}>&#9776;</span>
                    </div>
                </nav>

                <div id="myNav" className="overlay" style={{ "height": navHeight }}>
                <img src={logo} className="img-fluid" style={{"height":"100px"}} alt="logo"></img>
                    <a href="javascript:void(0)" className="closebtn" onClick={() => setNavHeight("0%")}><img src={cross} alt="close" style={{"width":"20px"}}></img></a>
                    <div className="overlay-content">
                        <div>
                        <Link to="about" activeClass="active" spy={true} smooth={true} onClick={() => setNavHeight("0%")} className={`nav-link overlay-m active  pb-1   ${lang==="en"?'f-en':'f-ar'}`}>
                            {headerTranslate[lang][0]}
                        </Link>
                        </div>
                        <div>
                        <Link to="ourservices" spy={true} smooth={true} onClick={() => setNavHeight("0%")}className={`nav-link overlay-m  pb-1   ${lang==="en"?'f-en':'f-ar'}`} >{headerTranslate[lang][1]}
                        </Link>
                        </div>
                        <div>
                        <Link to="ranking" spy={true} smooth={true} onClick={() => setNavHeight("0%")} className={`nav-link overlay-m  pb-1   ${lang==="en"?'f-en':'f-ar'}`} >{headerTranslate[lang][2]}
                        </Link>
                        </div>
                        <div>
                        <Link to="training" spy={true} smooth={true} onClick={() => setNavHeight("0%")} className={`nav-link overlay-m  pb-1   ${lang==="en"?'f-en':'f-ar'}`}>{headerTranslate[lang][3]}
                        </Link>
                        </div>
                        <div>
                        <Link to="client" onClick={() => setNavHeight("0%")} spy={true} smooth={true} className={`nav-link overlay-m  pb-1   ${lang==="en"?'f-en':'f-ar'}`}>{headerTranslate[lang][4]}
                        </Link>
                        </div>
                        <div className={lang === "en" ? "ml-auto" : "mr-auto"}>
                            <a href="javascript:void(0)" className="nav-link pb-1 bb fss-20" onClick={() => {setNavHeight("0%");changeLanguage()}} >{lang === 'en' ? 'AR' : 'EN'}</a>
                        </div>
                    </div>
                </div>


            </div>
        </div>
    )
}

export default Header;