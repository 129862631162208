import './App.css';
import Header from './components/partials/header/header';
import Footer from './components/partials/footer/footer';
import About from './components/partials/about/about';
import Vision from './components/partials/vision/vision';
import Services from './components/partials/services/services';
import Ranking from './components/partials/vision/ranking';
import Security from './components/partials/vision/security';
import Client from './components/partials/vision/client';
import React, { useEffect, useState } from 'react';

export const LanguageContext = React.createContext('en')

function App() {
  const [lang, setLang] = useState(sessionStorage.getItem('ln') || 'en')
  useEffect(() => {
    sessionStorage.setItem('ln', lang)
    document.getElementsByTagName('BODY')[0].setAttribute('lang', lang)
    document.getElementsByTagName('BODY')[0].setAttribute('dir', lang === 'en' ? 'ltr' : 'rtl')

  }, [lang])
  const changeLanguage = () => {
    let ln = 'en'
    setLang(lang === ln ? 'ar' : 'en');
  }
  return (
    <LanguageContext.Provider value={{ lang, changeLanguage }}>
    <div>
      <div className={(lang==='en'?'app-bg':'app-bg-ar')+" cmb-5"}>
        <Header></Header>
        <About></About>
      </div>
      <Vision></Vision>
      <Services></Services>
      <Ranking></Ranking>
      <Security></Security>
      <Client></Client>
      <Footer></Footer>
    </div>
    </LanguageContext.Provider>
  );
}

export default App;
