import React from 'react';
import { LanguageContext } from '../../../App';
import './vision.css'

const visionTranslate = {
    en: {
        vision: { title: 'VISION', content: 'Our vision is to continue to be the leader in the security service market and to provide to our clients a holistic Security service. We, SSCo align our goals with the vision 2030 and thrive to improve the security services provided.' },
        mission: { title: 'MISSION', content: 'To provide our clients with the upmost security service to their premises, assets, and clients. Ensuring to have the most advanced security systems in place always.' }
    },
    ar: {
        vision: { title: 'رؤيتنا', content: 'الاستمرارية في أن نكون الشركة الرائدة  في سوق خدمات الأمن وفق رؤية 2030م .تتمثل رؤيتنا في استمرارنا ان نكون الشركة الرائدة في سوق خدمات الأمن،  وأن نقدم لعملائنا خدمة امنية شاملة.ونحن كشركة الأمن والأمان ننسق أهدافنا مع رؤية المملكة  2030م ونتطور لتحسين ومواكبة خدمات الأمن  المقدمة' },
        mission: { title: 'مهمتنا', content: 'تقديم أفضل الخدمات الأمنية وتوفيرها لكافة عملائنا، وتزويدهم بأفضل خدمة أمنية لمبانيهم وأصولهم وعملائهم، وتوفير انظمة أمان اكثر تقدماً مواكبة لمتطلبات الخدمة.' }
    }
}

function Vision() {
    const { lang } = React.useContext(LanguageContext);
    return (
        <div className={(lang==="en"?'vision-bg f-en ':'vision-bg-ar f-ar ') +" cpx-5 pt-5 pb-5  text-justify"}>
            <h1 className="fs-3" >{visionTranslate[lang]['vision']['title']}</h1>
            <p className={`${lang==="en"?"fs-24 ":"afs-20 "} para-w `}>{visionTranslate[lang]['vision']['content']}</p>

            <h1  className="fs-3" >{visionTranslate[lang]['mission']['title']}</h1>
            <p className={`${lang==="en"?"fs-24 ":"afs-20 "} para-w mb-0 `}>{visionTranslate[lang]['mission']['content']}</p>
        </div>
    )
}

export default Vision;